/* Style for the container */
.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f3f4f6; /* Light gray background */
    color: #333; /* Dark text color */
  }
  
  /* Style for the title */
  .title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #007bff; /* Primary blue color */
  }
  
  /* Style for the search container */
  .search-container {
    position: sticky;
    top: 0;
    background-color: #fff; /* White background */
    z-index: 1000;
    padding: 10px 0;
    border-bottom: 1px solid #ccc; /* Light gray border */
  }
  
/* Style for the search input */
.search-input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    color: #495057;
  }
  
  /* Style for the search icon */
  .search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #6c757d;
  }
  
  
  /* Style for the list */
  .job-list {
    list-style: none;
    padding: 0;
  }
  
  /* Style for each list item */
  .job-item {
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #fff; /* White background */
  }
  
  /* Style for the job title */
  .job-title {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333; /* Dark color for job titles */
  }
  
  /* Style for job details */
  .job-detail {
    font-size: 16px;
    margin-bottom: 5px;
    color: #6c757d; /* Dark gray color for job details */
  }
  
  /* Responsive styles */
  @media only screen and (max-width: 768px) {
    .container {
      padding: 10px;
    }
    .title {
      font-size: 20px;
    }
    .search-input {
      padding: 8px 20px;
    }
    .job-item {
      padding: 15px;
    }
  }
  
  @media only screen and (max-width: 576px) {
    .container {
      max-width: 100%;
    }
    .title {
      font-size: 18px;
    }
    .search-input {
      padding: 6px 16px;
      font-size: 14px;
    }
  }

  #root {
      background-color: #333;
  }

  #header {
      background-color: #e4f670;
  }
  